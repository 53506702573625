import { Box, Grid, Typography } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { useEffect, useState } from "react";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "../../translation/translate";

export default function SubmitIt() {
  const { user } = useAppSelector((state) => state.account);
  const [navToScholarshps, setNavToScholarships] = useState(false);
  const [loadingPreviewPDF, setLoadingPreviewPDF] = useState(false);
  const { getTranslation } = useTranslation();
  const [isFinalPDFsent, setIsFinalPDFsent] = useState<boolean>(false);

  const handleScholarships = () => {
    setNavToScholarships(true);
    const url = "https://www.cdacouncil.org/en/find-cda-scholarships/";
    window.open(url, "_blank");
    setNavToScholarships(false);
  };

  //updating CDA Your Council PDF submission status
  const fetchpdfResponse = async () => {
    if (user) {
      const pdfResponse = await agent.YourCouncil.getPDFSubmissionStatus(
        user.portfolioId
      );
      setIsFinalPDFsent(pdfResponse);
    }
  };

  const handlePreviewPDF = async () => {
    setLoadingPreviewPDF(true);
    if (user) {
      try {
        //Generating the final PDF
        await agent.PDFViewer.GenerateFinalPDF(user.portfolioId ?? 0, "Final");
        const data = await agent.FileUpload.GetFileInfo(
          "FinalPDF",
          user.portfolioId ?? 0
        );
        // Get the file
        const response = await agent.FileUpload.GetFiles(data[0].id);

        // Create a blob from the file response
        const blob = new Blob([response], { type: "application/pdf" });

        // Create a URL for the blob
        const imageUrl = URL.createObjectURL(blob);

        //sending pdf to council
        const userPortfolioID = String(user.portfolioId);
        await agent.YourCouncil.sendPdfToCouncil(userPortfolioID);

        // Open the PDF in a new tab
        window.open(imageUrl, "_blank");
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setLoadingPreviewPDF(false);
      }
    }
  };

  useEffect(() => {
    fetchpdfResponse();
  }, []);

  return (
    <Grid item xs={12} mt={4} px={3} pt={3} sx={{ bgcolor: "#EEFCFB" }}>
      <Typography variant="body1" fontWeight="bold">
        3. {getTranslation("PuttingAllTogetherHeaderSubmitLine1")}
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            {getTranslation("PuttingAllTogetherHeaderSubmitLine2")}
            <br />
            {getTranslation("PuttingAllTogetherHeaderSubmitLine3")}
          </Typography>
        </li>

        <LoadingButton
          variant="outlined"
          color="secondary"
          onClick={handlePreviewPDF}
          disabled={isFinalPDFsent}
          loading={loadingPreviewPDF}
          style={{ textTransform: "initial", marginBottom: "15px" }}
        >
          {getTranslation("PuttingAllTogetherHeaderSubmitLine4")}
        </LoadingButton>
        <li>
          <Typography variant="body1">
            {getTranslation("PuttingAllTogetherHeaderSubmitLine5")}

            <br />
            {getTranslation("PuttingAllTogetherHeaderSubmitLine6")}
          </Typography>
        </li>
        <Box mt={2}>
          <LoadingButton
            variant="outlined"
            color="secondary"
            onClick={handleScholarships}
            loading={navToScholarshps}
            style={{ textTransform: "initial", marginBottom: "15px" }}
          >
            {getTranslation("PuttingAllTogetherHeaderSubmitLine7")}
          </LoadingButton>
        </Box>
      </ul>
    </Grid>
  );
}
