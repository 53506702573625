import { createBrowserRouter } from "react-router-dom";
import App from "../layout/App";
import CDABook from "../../features/welcome/CDABook";
import Welcome from "../../features/welcome/Welcome";
import ChooseCredential from "../../features/welcome/ChooseCredential";
import Register from "../../features/account/Register";
import Login from "../../features/account/Login";
import RequireAuth from "./RequireAuth";
import ResetPassword from "../../features/account/ResetPassword";
import ConfirmEmail from "../../features/account/ConfirmEmail";
import ChangePassword from "../../features/account/ChangePassword";
import Results from "../../features/account/Results";
import HowToVideo from "../../features/welcome/HowToVideo";
import MyProgressSummary from "../../features/progressSummary/MyProgressSummary";
import Cdaeducation from "../../features/cdaeducation/Cdaeducation";
import CompetencyIStatement from "../../features/compentencyi/Statement1";
import CompetencyIResourceCollection from "../../features/compentencyi/ResourceCollectionI";
import CompetencyIIStatement from "../../features/competencyii/CompetencyIIStatement";
import CompetencyIIIStatement from "../../features/competencyiii/CompetencyIIIStatement";
import CompetencyIIIResourceCollection from "../../features/competencyiii/CompetencyIIIResourceCollection";
import CompetencyIVResourceCollection from "../../features/competencyIV/CompetencyIVResourceCollection";
import CompetencyIVStatement from "../../features/competencyIV/CompetencyIVStatement";
import CompetencyVResourceCollection from "../../features/competencyV/CompetencyVResourceCollection";
import CompetencyVStatement from "../../features/competencyV/CompetencyVStatement";
import CompetencyVIResourceCollection from "../../features/competencyvi/CompetencyVIResourceCollection";
import CompetencyVIStatement from "../../features/competencyvi/CompetencyVIStatement";
import EditProfile from "../../features/account/EditProfile";
import ResourceCollectionII from "../../features/competencyii/ResourceCollectionII";
import VerificationVisitWorksheet from "../../features/VerificationVisitReflective/VerificationVisitWorksheet";
import ThePhilosophyStatement from "../../features/ProfessionalPhilosophyStatement/ThePhilosophyStatement";
import FamilyQuestionnairesSummary from "../../features/familyquestionnairessummary/FamilyQuestionnairesSummary";
import EducationEdoc from "../../features/cdaeducation/EducationEform";
import HelpAndFaqs from "../../features/help/HelpAndFaqs";
import ResourceLibraryCollection from "../../features/ResourceLibrary.tsx/ResourceLibraryCollection";
import UploadDownloadCollection from "../../features/HowToUploadDownload/UploadDownloadCollection";
import Dashboard from "../../features/admin/dashboard/dashboard";
import Reports from "../../features/admin/reports/reports";
import ManageUsers from "../../features/admin/Users/ManageUsers";
import EditUser from "../../features/admin/Users/EditUser";
import Unauthorized from "../../features/errors/unauthorized";
import PuttingItAllTogether from "../../features/puttingitalltogether/PuttingItAllTogether";
import ProfessionalPortfolio from "../../features/PortfolioChecklist/ProfessionalPortfolio";
import ProfessionalChecklist from "../../features/PortfolioChecklist/ProfessionalChecklist";
import RegistrationSuccess from "../../features/account/RegistrationSuccess";
import EFormQuestionnaire from "../../features/familyquestionnairessummary/EFormQuestionnaire";
import MyUploads from "../../features/MyUploads/MyUploads";
import Landing from "../../features/landing/Landing";
import OnlineCoverSheet from "../../features/PortfolioChecklist/OnlineCoverSheet/PortfolioOnlineCoverSheet";
import VerificationEForm from "../../features/VerificationVisitReflective/VerifyEForm";
import ManageStudents from "../../features/Trainer/students/manageStudents";
import ManageClasses from "../../features/Trainer/classes/manageClasses";
import TrainerDashboard from "../../features/Trainer/dashboard/trainerDashboard";
import ClassStudentData from "../../features/Trainer/classes/classStudentDataTable";
import EmailTemplate from "../../features/admin/Email-Template/emailTemplate";
import TranslationFormEditor from "../../translation/editTranslation";
import LoginSSO from "../../features/account/LoginSSO";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      // authenticated routes
      {
        element: <RequireAuth />,
        children: [
          { path: "/welcome", element: <Welcome /> },
          { path: "/welcome/cdabook", element: <CDABook /> },
          { path: "/welcome/credential", element: <ChooseCredential /> },
          { path: "/welcome/How-To-Video", element: <HowToVideo /> },
          { path: "/cdaeducation", element: <Cdaeducation /> },
          { path: "/cdaeducaion/edocument", element: <EducationEdoc /> },
          { path: "/myprogresssummary", element: <MyProgressSummary /> },
          {
            path: "/familyquestionnairessummary",
            element: <FamilyQuestionnairesSummary />,
          },
          {
            path: "/familyquestionnairessummary/EForm",
            element: <EFormQuestionnaire />,
          },
          { path: "/editprofile", element: <EditProfile /> },
          { path: "/competencyi/statement", element: <CompetencyIStatement /> },
          {
            path: "/competencyi/resourcecollection",
            element: <CompetencyIResourceCollection />,
          },
          {
            path: "/competencyii/statement",
            element: <CompetencyIIStatement />,
          },
          {
            path: "/competencyii/resourcecollection",
            element: <ResourceCollectionII />,
          },
          {
            path: "/competencyiii/statement",
            element: <CompetencyIIIStatement />,
          },
          {
            path: "/competencyiii/resourcecollection",
            element: <CompetencyIIIResourceCollection />,
          },
          {
            path: "/competencyiv/statement",
            element: <CompetencyIVStatement />,
          },
          {
            path: "/competencyiv/resourcecollection",
            element: <CompetencyIVResourceCollection />,
          },
          { path: "/competencyv/statement", element: <CompetencyVStatement /> },
          {
            path: "/competencyv/resourcecollection",
            element: <CompetencyVResourceCollection />,
          },
          {
            path: "/competencyvi/statement",
            element: <CompetencyVIStatement />,
          },
          {
            path: "/competencyvi/resourcecollection",
            element: <CompetencyVIResourceCollection />,
          },
          {
            path: "/myverificationvisit",
            element: <VerificationVisitWorksheet />,
          },
          {
            path: "/myverificationvisit/onlineform",
            element: <VerificationEForm />,
          },
          { path: "/philosophystatement", element: <ThePhilosophyStatement /> },
          { path: "/help/helpandfaqs", element: <HelpAndFaqs /> },
          { path: "/resourcelibrary", element: <ResourceLibraryCollection /> },
          { path: "/myuploads", element: <MyUploads /> },
          { path: "/downloadupload", element: <UploadDownloadCollection /> },
          { path: "/portfoliochecklist", element: <ProfessionalPortfolio /> },
          {
            path: "/portfoliochecklist/OnlineCoverSheet",
            element: <OnlineCoverSheet />,
          },
          {
            path: "/portfoliochecklist/onlineform",
            element: <ProfessionalChecklist />,
          },
          {
            path: "/puttingitalltogether",
            element: <PuttingItAllTogether />,
          },
        ],
      },

      //admin Routes
      {
        element: <RequireAuth role={["CSI Admin", "Council Admin"]} />,
        children: [
          { path: "/admin/dashboard", element: <Dashboard /> },
          { path: "/admin/reports", element: <Reports /> },
          { path: "/admin/manageusers", element: <ManageUsers /> },
          { path: "/admin/edituser/:userId", element: <EditUser /> },
          { path: "/admin/emailtemplate", element: <EmailTemplate /> },
          {
            path: "/admin/translation-editor",
            element: <TranslationFormEditor />,
          },
        ],
      },

      //trainer and "Other" routes
      {
        element: <RequireAuth role={["Trainer"]} />,
        children: [
          { path: "/trainer/dashboard", element: <TrainerDashboard /> },
          { path: "/trainer/classes", element: <ManageClasses /> },
          {
            path: "/trainer/class-student-list",
            element: <ClassStudentData />,
          },
          { path: "/trainer/students", element: <ManageStudents /> },
          { path: "/trainer/edituser/:userId", element: <EditUser /> },
          { path: "/trainer/emailtemplate", element: <EmailTemplate /> },
        ],
      },

      { path: "/", element: <LoginSSO /> },
      { path: "/loginSSO", element: <LoginSSO /> },
      { path: "/unauthorized", element: <Unauthorized /> },
      { path: "/registersuccess", element: <RegistrationSuccess /> },
      { path: "/account/register", element: <Register /> },
      { path: "/account/login", element: <Login /> },
      { path: "/account/resetpassword", element: <ResetPassword /> },
      { path: "/account/confirmemail", element: <ConfirmEmail /> },
      { path: "/account/changepassword", element: <ChangePassword /> },
      { path: "/account/resetconfirmation", element: <Results /> },
      { path: "/account/changePasswordconfirmation", element: <Results /> },
    ],
  },
]);
