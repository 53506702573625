import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../app/store/configureStore";
import { signInUserSSO } from "./accountSlice";
import LoadingSpinner from "../../app/common/LoadingSpinner";

interface UserDto {
  id: string;
  username: string;
  token: string; // Raw token
  role: string;
  welcomeComplete: boolean;
  email: string;
  firstName: string;
  lastName: string;
  credentialName?: string | null; // Nullable
  credentialTypeId?: number | null; // Nullable
  cdaCouncilCandidateId?: number | null; // Nullable
  languageSpecialization?: string | null; // Nullable
  portfolioId?: number | null; // Nullable
  homeState?: string | null; // Nullable
  phoneNumber?: string | null; // Nullable
  homeZipCode?: string | null; // Nullable
  isActive: boolean;
  isSpanish?: boolean;
}

const LoginSSO: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Parse the query string to retrieve the user object
  const params = new URLSearchParams(window.location.search);
  const userString = params.get("user") ?? "";
  let role = params.get("role") ?? "";

  if(role == "") {
     role = localStorage.getItem("role") ?? "";
  }else {
    localStorage.setItem("role", role);
  }
  
  let userObject: UserDto | null = null;
  let userObjectFromStorage: UserDto | null = null;

  let tokenPayload: any | null = null;
  let decodedUser = "";

  // Use the toCamelCase function to convert the keys to camelCase
  const toCamelCase = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map((v) => toCamelCase(v));
    } else if (obj !== null && typeof obj === "object") {
      return Object.keys(obj).reduce((result: any, key: string) => {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        result[newKey] = toCamelCase(obj[key]);
        return result;
      }, {});
    }
    return obj;
  };

  const getUserFromStorage = (): UserDto | null => {
    const userFromStorage = localStorage.getItem("user");

    // Check if userFromStorage is not null and non-empty
    if (userFromStorage && userFromStorage.trim() !== "") {
      try {
        // Attempt to parse the JSON string
        return toCamelCase(JSON.parse(userFromStorage));
      } catch (error) {
        console.error("Error parsing user data from storage:", error);
        return null; // Return null if parsing fails
      }
    } else {
      console.log("No valid user data found in localStorage.");
      return null; // Return null if the data is empty or null
    }
  };

  const getCorrectedRoleName = (role: string): string => {
    const roleDictionary: { [key: string]: string } = {
      "cdaapplicant": "CDA Candidate",
      "otherviewer": "Other Viewer",
      "cdatrainer": "Trainer",
    };
  
    // Return the corrected role name or the original role if not found in the dictionary
    return roleDictionary[role.toLowerCase()] || role;
  };

  // Convert the localStorage user to camelCase
  userObjectFromStorage = getUserFromStorage();

  if (userObjectFromStorage) {
    userObject = userObjectFromStorage;
  } else if (userString) {
    try {
      decodedUser = decodeURIComponent(userString); // Decode the URL-encoded string

      // Check if decodedUser is a valid JSON string
      if (decodedUser && decodedUser.trim() !== "") {
        userObject = toCamelCase(JSON.parse(decodedUser)); // Parse the JSON into a user object
      } else {
        console.log("Decoded user string is empty or invalid.");
      }
    } catch (error) {
      console.error("Error parsing user string from URL:", error);
    }
  } else {
    console.log("User string is missing or empty.");
  }

  const isTokenValid = (token: string) => {
    if (!token) {
      console.error("Token is missing");
      return false;
    } else {
      if (token.split(".").length !== 3) {
        console.error("Invalid token format");
      } else {
        tokenPayload = JSON.parse(window.atob(token.split(".")[1]));
      }
    }

    try {
      const currentTime = Math.floor(Date.now() / 1000);
      return tokenPayload.exp > currentTime; // Check if the token is expired
    } catch (error) {
      console.error("Error parsing token to get Experation Date:", error);
      return false;
    }
  };

  useEffect(() => {
    const handleToken = async () => {
      if (userObject && isTokenValid(userObject.token)) {

        // localStorage.setItem("role", getCorrectedRoleName(role));
        userObject.role = getCorrectedRoleName(role);;
        await dispatch(signInUserSSO(userObject));
        
        // Redirect the user based on role or welcome status
        navigateUser(userObject);
      } else {
        const encodedRole = encodeURIComponent(role);

        // Redirect to SSO login page based on the environment
        const currentURL = window.location.href;
        let redirectToSSO = "";

        if (currentURL.includes("localhost")) {
          redirectToSSO = `https://localhost:5000/api/Saml2/sso?role=${encodedRole}`;
        } else if (currentURL.includes("dev.")) {
          redirectToSSO = `https://dev.api.yourcdaportfolio.org/api/Saml2/sso?role=${encodedRole}`;
        } else if (currentURL.includes("test.")) {
          redirectToSSO = `https://test.api.yourcdaportfolio.org/api/Saml2/sso?role=${encodedRole}`;
        } else {
          redirectToSSO = `https://api.yourcdaportfolio.org/api/Saml2/sso?role=${encodedRole}`;
        }
        window.location.href = redirectToSSO;
      }
    };

    handleToken();
  }, [tokenPayload, dispatch, navigate, role]);

  // Function to handle navigation based on user role and welcome status
  const navigateUser = (user: UserDto) => {

    // Check if the user has a role and welcome status
    const { role, welcomeComplete } = user;
    if (role === "CSI Admin" || role === "Council Admin") {

      navigate("/admin/dashboard");
    } else if (role === "Trainer") {

      navigate("/trainer/students");
    } else if (role === "Other Viewer") {

      navigate("/editprofile");
    } else if (welcomeComplete) {

      navigate("/myprogresssummary");
    } else {

      navigate("/welcome");
    }
  };

  return <LoadingSpinner />;
};

export default LoginSSO;
